import React, { Component } from "react";

import "../style/Endgame.scss";

class Endgame extends Component {
  render() {
    return (
      <div className="Endgame">
        <div className="title">Look at all this #swag!</div>
        <div className="playerList">
          {this.props.room.matches.map((match, index) => {
            return (
              <div className="avatar" key={index}>
                <div className="playerName">{match.name} got</div>
                <div
                  className="elephantAvatar"
                  style={{
                    backgroundImage: `url(../wrappings/${
                      this.props.room.presents[match.gift].wrapping
                    })`,
                  }}
                  alt="avatar"
                ></div>
                <div className="playerName">
                  {this.props.room.presents[match.gift].description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Endgame;
