import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { PlayerInfo } from "../components";
import apis from "../api";

class Home extends Component {
  startNewGame = async (name, present, clue, wrapping) => {
    const player = {
      name: name,
      present: present,
      clue: clue,
      wrapping: wrapping,
    };
    await apis.newGame(player).then((res) => {
      this.props.changeState("name", name);
      this.props.changeState("gift", present);
      this.props.changeState("clue", clue);
      this.props.changeState("wrapping", wrapping);
      this.props.changeState("startingPlayer", true);
      this.props.socket.emit("join", {
        name: name,
        room: res.data.data.id,
        first: true,
      });
      localStorage.setItem("room", res.data.data.id);
      this.props.history.push(`/room/${res.data.data.id}`);
    });
  };

  render() {
    return (
      <PlayerInfo handleSubmit={this.startNewGame.bind(this)} newGame="true" />
    );
  }
}

export default withRouter(Home);
