import React, { Component } from "react";
import Gift from "./Gift";

import "../style/GiftPile.scss";

class GiftPile extends Component {
  render() {
    return (
      <div className="GiftPile">
        <div className="PileHeader title">Gift Pile</div>
        <div className="GiftList">
          {this.props.unclaimed.map((gift, index) => {
            return (
              <Gift
                gift={gift}
                key={index}
                pick={this.props.pick}
                isActivePlayer={this.props.isActivePlayer}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default GiftPile;
