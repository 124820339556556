import React, { Component } from "react";
import GameOrder from "./GameOrder";
import GiftPile from "./GiftPile";

class GameBoard extends Component {
  render() {
    return (
      <div className="GameBoard">
        <GameOrder
          room={this.props.room}
          steal={this.props.steal}
          isActivePlayer={this.props.isActivePlayer}
          lastSwap={this.props.lastSwap}
          swap={this.props.swap}
          endGame={this.props.endGame}
          currentEvent={this.props.currentEvent}
        />
        <GiftPile
          unclaimed={this.props.room.unclaimed}
          pick={this.props.pick}
          isActivePlayer={this.props.isActivePlayer}
        />
      </div>
    );
  }
}

export default GameBoard;
