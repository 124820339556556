/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "../style/WaitingRoom.scss";

class WaitingRoom extends Component {
  render() {
    //const url = `http://white-elephant.club/room/${this.props.roomID}`
    return (
      <div className="waitingRoom">
        <div className="title">Look Who's Already Here</div>
        <CopyToClipboard
          text={`http://white-elephant.club/room/${this.props.roomID}`}
        >
          <div className="joinLink">
            <span>
              Click to copy room link: http://white-elephant.club/room/
              {this.props.roomID}
            </span>
          </div>
        </CopyToClipboard>
        <div className="playerList">
          {this.props.players.map((player, index) => (
            <div className="avatar" key={index}>
              <div
                className="elephantAvatar"
                style={{
                  backgroundImage: `url(../elephants/${player.elephant})`,
                }}
                alt="avatar"
              ></div>
              <div className="playerName">{player.name}</div>
            </div>
          ))}
        </div>
        {this.props.startingPlayer && (
          <div className="buttonContainer">
            <button
              className="bigButton"
              onClick={this.props.startExchange}
              disabled={this.props.players.length < 2}
            >
              <span>We're Ready</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default WaitingRoom;
