import axios from "axios";

const api = axios.create({
  baseURL: process.env.PUBLIC_URL,
});

export const newGame = (payload) => api.post(`/api/new`, payload);
export const roomInfo = (id) => api.get(`/api/room/${id}`);

const apis = {
  newGame,
  roomInfo,
};

export default apis;
