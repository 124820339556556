import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import "../style/Gift.scss";

class Gift extends Component {
  render() {
    return (
      <div className="Gift">
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <Popover
              id={`${this.props.gift.id}-popover`}
              container="body"
              className="customPopover"
            >
              <Popover.Title className="customPopoverTitle">
                {this.props.gift.clue}
              </Popover.Title>
              <Popover.Content className="customPopoverContent">
                <button
                  className="smallButton"
                  onClick={() => this.props.pick(this.props.gift.id)}
                  disabled={!this.props.isActivePlayer}
                >
                  <span>Pick</span>
                </button>
              </Popover.Content>
            </Popover>
          }
        >
          <div
            className="GiftWrapping"
            style={{
              backgroundImage: `url(../wrappings/${this.props.gift.wrapping})`,
            }}
            alt="wrapping"
          ></div>
        </OverlayTrigger>
      </div>
    );
  }
}

export default Gift;
