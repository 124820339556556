import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import apis from "../api";
import { WaitingRoom, GameBoard, PlayerInfo, Endgame } from "../components";

class Exchange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      room: null,
      started: false,
      ended: false,
      isActivePlayer: false,
      lastSwap: false,
      players: [],
      currentEvent: "",
    };

    this.props.socket.on("joined", (room) => {
      this.setState({ room: room, players: room.players });
    });

    this.props.socket.on("started", (room) => {
      this.setState({
        room: room,
        started: true,
        isActivePlayer: this.determineActivePlayer(room),
      });
    });

    this.props.socket.on("picked", (picked) => {
      this.setState({
        room: picked.room,
        isActivePlayer: this.determineActivePlayer(picked.room),
        lastSwap: picked.room.unclaimed.length === 0,
        currentEvent: `${picked.picker} picked ${picked.gift}`,
      });
    });

    this.props.socket.on("stolen", (stolen) => {
      this.setState({
        room: stolen.room,
        isActivePlayer: this.determineActivePlayer(stolen.room),
        currentEvent: `${stolen.thief} stole ${stolen.gift} from ${stolen.from}`,
      });
    });

    this.props.socket.on("swapped", (room) => {
      this.setState({ room: room, ended: true });
    });

    this.props.socket.on("game-ended", (room) => {
      this.setState({ room: room, ended: true });
    });
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    try {
      const res = await apis.roomInfo(id);
      const room = res.data.room;
      this.setState({
        room: room,
        started: room.started,
        isActivePlayer: this.determineActivePlayer(room),
        players: room.players,
        ended: room.ended,
        lastSwap: room.started && room.unclaimed.length === 0,
      });
    } catch (err) {
      this.props.history.push("/");
    }
  };

  joinExchange = (name, gift, clue, wrapping) => {
    if (this.state.room) {
      this.props.socket.emit("join", {
        name: name,
        room: this.state.room.id,
        gift: gift,
        clue: clue,
        wrapping: wrapping,
        first: false,
      });
      localStorage.setItem("room", this.state.room.id);
      localStorage.setItem("name", name);
      localStorage.setItem("gift", gift);
      localStorage.setItem("clue", clue);
      localStorage.setItem("wrapping", wrapping);
      localStorage.setItem("startingPlayer", false);
    }
  };

  startExchange = () => {
    this.props.socket.emit("start", {
      room: this.state.room.id,
    });
  };

  pick = (id) => {
    this.props.socket.emit("pick", {
      room: this.state.room.id,
      giftID: id,
      player: localStorage.getItem("name"),
    });
  };

  steal = (id, from) => {
    this.props.socket.emit("steal", {
      room: this.state.room.id,
      giftID: id,
      thief: localStorage.getItem("name"),
      from: from,
    });
  };

  swap = (id, swapWith) => {
    this.props.socket.emit("swap", {
      room: this.state.room.id,
      giftID: id,
      swapper: localStorage.getItem("name"),
      swapWith: swapWith,
    });
  };

  endGame = () => {
    this.props.socket.emit("end-game", {
      room: this.state.room.id,
    });
  };

  determineActivePlayer = (room) => {
    if (!room.started) {
      return false;
    }
    if (room.order.length > 0) {
      return localStorage.getItem("name") === room.order[0].name;
    } else {
      return localStorage.getItem("name") === room.first;
    }
  };

  render() {
    let canvas;
    if (this.state.started && !this.state.ended) {
      canvas = (
        <GameBoard
          {...this.state}
          steal={this.steal.bind(this)}
          pick={this.pick.bind(this)}
          swap={this.swap.bind(this)}
          endGame={this.endGame.bind(this)}
        />
      );
    } else if (this.state.ended) {
      canvas = <Endgame room={this.state.room}></Endgame>;
    } else {
      canvas = (
        <div className="Game">
          {localStorage.getItem("room") === this.props.match.params.id &&
          localStorage.getItem("name") &&
          localStorage.getItem("gift") ? (
            <WaitingRoom
              startExchange={this.startExchange.bind(this)}
              players={this.state.players}
              startingPlayer={this.props.startingPlayer}
              roomID={this.state.room === null ? "" : this.state.room.id}
            />
          ) : (
            <PlayerInfo
              handleSubmit={this.joinExchange.bind(this)}
              newGame="false"
            />
          )}
        </div>
      );
    }
    return <div>{canvas}</div>;
  }
}

export default withRouter(Exchange);
