import React from "react";

import "../style/Footer.scss";

function Footer() {
  return (
    <div className="Footer">
      <p>
        Made with <span>&hearts;</span> for the Bit Palace Book Club
      </p>
    </div>
  );
}

export default Footer;
