import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Exchange } from "../pages";
import { Footer } from "../components";

import socketIOClient from "socket.io-client";

import "../style/Common.scss";

const isDev = process.env.NODE_ENV === "development";
const socket = socketIOClient(
  isDev ? "http://localhost:3000" : process.env.PUBLIC_URL
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: localStorage.getItem("name") || "",
      present: localStorage.getItem("gift") || "",
      clue: localStorage.getItem("clue") || "",
      wrapping: localStorage.getItem("wrapping") || "default.jpg",
      startingPlayer:
        localStorage.getItem("startingPlayer") === "true" ? true : false,
    };
  }

  changeState = (attribute, value) => {
    localStorage.setItem(attribute, value);

    this.setState({
      [attribute]: value,
    });
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/room/:id">
            <Exchange
              {...this.state}
              changeState={this.changeState}
              socket={socket}
            />
          </Route>
          <Route path="/">
            <Home
              {...this.state}
              changeState={this.changeState}
              socket={socket}
            />
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
