import React, { Component } from "react";

import "../style/PlayerInfo.scss";

class PlayerInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validUsername: false,
      validGift: false,
      currentUsername: "",
      currentGift: "",
      currentClue: "",
      currentWrapping: "santa.png",
    };
  }

  validateGift = (e) => {
    if (e.target.value.length > 3) {
      this.setState({ validGift: true, currentGift: e.target.value });
    } else {
      this.setState({ validGift: false, currentGift: e.target.value });
    }
  };

  validateUsername = (e) => {
    if (e.target.value.length > 3) {
      this.setState({ validUsername: true, currentUsername: e.target.value });
    } else {
      this.setState({ validUsername: false, currentUsername: e.target.value });
    }
  };

  setClue = (e) => {
    this.setState({ currentClue: e.target.value });
  };

  setWrapping = (e) => {
    this.setState({ currentWrapping: e.target.value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.handleSubmit(
      this.state.currentUsername,
      this.state.currentGift,
      this.state.currentClue,
      this.state.currentWrapping
    );
  };

  render() {
    return (
      <div className="playerInfo">
        <form className="playerInfoForm" onSubmit={this.handleOnSubmit}>
          <span className="title">
            {this.props.newGame === "true"
              ? "Start a New Exchange"
              : "Join an Exchange"}
            <br />
            <a
              className="wikiLink"
              target="_blank"
              rel="noreferrer"
              href="https://en.wikipedia.org/wiki/White_elephant_gift_exchange"
            >
              Click here if you don't know how to play
            </a>
          </span>
          <div className="wrapInput">
            <input
              className="playerInput"
              type="text"
              name="name"
              placeholder="My Name"
              onChange={this.validateUsername}
            />
            <span className="focusPlayerInput"></span>
          </div>
          <div className="wrapInput">
            <textarea
              className="playerInput"
              name="gift"
              placeholder="The Gift I Brought (this will remain hidden until your gift is picked by someone)"
              onChange={this.validateGift}
            />
            <span className="focusPlayerInput"></span>
          </div>
          <div className="wrapInput">
            <textarea
              className="playerInput"
              name="clue"
              placeholder="My Cryptic Description (what others will see before unwrapping)"
              onChange={this.setClue}
            />
            <span className="focusPlayerInput"></span>
          </div>
          <div className="wrapRadio">
            <p>My Wrapping Paper</p>
            <div className="wrapSelector">
              <input
                id="santa"
                type="radio"
                name="wrap"
                value="santa.png"
                defaultChecked
                onClick={this.setWrapping}
              />
              <label
                className="wrapLabel santa"
                htmlFor="santa"
                style={{ backgroundImage: "url(../wrappings/santa.png" }}
              ></label>
              <input
                id="red-tartan"
                type="radio"
                name="wrap"
                value="red-tartan.jpg"
                onClick={this.setWrapping}
              />
              <label
                className="wrapLabel red-tartan"
                htmlFor="red-tartan"
                style={{ backgroundImage: "url(../wrappings/red-tartan.jpg" }}
              ></label>
              <input
                id="craft-paper"
                type="radio"
                name="wrap"
                value="craft-paper.png"
                onClick={this.setWrapping}
              />
              <label
                className="wrapLabel craft-paper"
                htmlFor="craft-paper"
                style={{ backgroundImage: "url(../wrappings/craft-paper.png" }}
              ></label>
              <input
                id="franzen"
                type="radio"
                name="wrap"
                value="franzen.png"
                onClick={this.setWrapping}
              />
              <label
                className="wrapLabel franzen"
                htmlFor="franzen"
                style={{ backgroundImage: "url(../wrappings/franzen.png" }}
              ></label>
              <input
                id="popsicles"
                type="radio"
                name="wrap"
                value="popsicles.png"
                onClick={this.setWrapping}
              />
              <label
                className="wrapLabel popsicles"
                htmlFor="popsicles"
                style={{ backgroundImage: "url(../wrappings/popsicles.png" }}
              ></label>
            </div>
          </div>
          <div className="buttonContainer">
            <button
              className="bigButton"
              disabled={!(this.state.validGift && this.state.validUsername)}
              onSubmit={this.handleOnSubmit}
            >
              <span>{this.props.newGame === "true" ? "Create" : "Join"}</span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default PlayerInfo;
