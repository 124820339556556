import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import "../style/ClaimedGift.scss";

class ClaimedGift extends Component {
  render() {
    let playerGift;
    let stealButton;
    if (
      this.props.claimed &&
      this.props.claimed.length > 0 &&
      this.props.claimed.includes(this.props.player.gift)
    ) {
      stealButton = (
        <button
          className="smallButton"
          onClick={() =>
            this.props.steal(this.props.player.gift, this.props.player.name)
          }
          disabled
        >
          <span>I'm Taken</span>
        </button>
      );
    } else if (this.props.lastSwap) {
      stealButton = (
        <div className="swapButtons">
          <button
            className="smallButton swapButton"
            onClick={() =>
              this.props.swap(this.props.player.gift, this.props.player.name)
            }
            disabled={!this.props.isActivePlayer}
          >
            <span>Swap</span>
          </button>
          <button
            className="smallButton swapButton"
            onClick={() => this.props.endGame()}
            disabled={!this.props.isActivePlayer}
          >
            <span>I'm Good</span>
          </button>
        </div>
      );
    } else {
      stealButton = (
        <button
          className="smallButton"
          onClick={() =>
            this.props.steal(this.props.player.gift, this.props.player.name)
          }
          disabled={!this.props.isActivePlayer}
        >
          <span>Steal</span>
        </button>
      );
    }
    if (
      "gift" in this.props.player &&
      (!this.props.lastSwap || this.props.player.name !== this.props.first)
    ) {
      playerGift = (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <Popover
              id={`${this.props.player.gift}-claimed-popover`}
              container="body"
              className="customPopover"
            >
              <Popover.Title className="customPopoverTitle">
                {this.props.present.description}
              </Popover.Title>
              <Popover.Content className="customPopoverContent">
                {stealButton}
              </Popover.Content>
            </Popover>
          }
        >
          <div className="ClaimedGift">
            <div className="avatar">
              <div
                className="elephantAvatar"
                style={{
                  backgroundImage: `url(../wrappings/${this.props.present.wrapping})`,
                }}
                alt="avatar"
              ></div>
              <div className="playerName">{this.props.player.name}</div>
            </div>
          </div>
        </OverlayTrigger>
      );
    } else if (
      "gift" in this.props.player &&
      this.props.player.name === this.props.first &&
      this.props.lastSwap
    ) {
      playerGift = (
        <div className="ClaimedGift">
          <div className="avatar">
            <div
              className="elephantAvatar"
              style={{
                backgroundImage: `url(../wrappings/${this.props.present.wrapping})`,
              }}
              alt="avatar"
            ></div>
            <div className="playerName">{this.props.player.name}</div>
          </div>
        </div>
      );
    } else {
      playerGift = (
        <div className="ClaimedGift">
          <div className="avatar">
            <div
              className={`elephantAvatar ${
                this.props.index === 0 ? "currentAvatar" : "orderAvatar"
              }`}
              style={{
                backgroundImage: `url(../elephants/${this.props.player.elephant})`,
              }}
              alt="avatar"
            ></div>
            <div className="playerName">{this.props.player.name}</div>
          </div>
        </div>
      );
    }
    return playerGift;
  }
}

export default ClaimedGift;
