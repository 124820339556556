import React, { Component } from "react";
import ClaimedGift from "./ClaimedGift";

import "../style/GameOrder.scss";

class GameOrder extends Component {
  render() {
    return (
      <div className="GameOrder">
        <div className="title OrderHeader">
          {this.props.lastSwap ? (
            <div className="GameStatus">
              {this.props.room.first} can make one last swap
            </div>
          ) : (
            <div className="GameStatus">
              It's {this.props.room.order[0].name}'s turn
            </div>
          )}
        </div>
        <div className="Order">
          {this.props.room.matches.map((player, index) => (
            <ClaimedGift
              player={player}
              key={index}
              index={index}
              present={this.props.room.presents[player.gift]}
              steal={this.props.steal}
              claimed={this.props.room.claimed}
              isActivePlayer={this.props.isActivePlayer}
              lastSwap={this.props.lastSwap}
              swap={this.props.swap}
              endGame={this.props.endGame}
              first={this.props.room.first}
            ></ClaimedGift>
          ))}
          {this.props.room.order.map((player, index) => (
            <ClaimedGift
              player={player}
              key={index}
              index={index}
            ></ClaimedGift>
          ))}
        </div>

        <div className="title OrderHeader EventHeader">
          {this.props.currentEvent !== "" ? (
            <div className="GameStatus">{this.props.currentEvent}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default GameOrder;
